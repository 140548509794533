import { Box, Center, Grid, Link, Text, useToast } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import React, { useEffect, useRef } from "react";
import { InView } from "react-intersection-observer";
import YouTube from "react-youtube";
import Carousel from "../components/Carousel";
import ContactComponent from "../components/ContactComponent";
import HeroSlider from "../components/HeroSlider";
import Layout from "../components/Layout";
import useHover from "../hooks/useHover";

const MotionText = motion(Text);

const IMAGES = [
  {
    src: "/img/carousel/ae-carousel-1.jpg",
    thumbnail: "/img/carousel/ae-carousel-1.jpg",
    alt: "aevias meetings example one",
  },
  {
    src: "/img/carousel/ae-carousel-2.jpeg",
    thumbnail: "/img/carousel/ae-carousel-2.jpeg",
    alt: "aevias meetings example one",
  },
  {
    src: "/img/carousel/ae-carousel-3.jpg",
    thumbnail: "/img/carousel/ae-carousel-3.jpg",
    alt: "aevias meetings example one",
  },
  {
    src: "/img/carousel/ae-carousel-4.jpeg",
    thumbnail: "/img/carousel/ae-carousel-4.jpeg",
    alt: "aevias meetings example one",
  },
  {
    src: "/img/carousel/ae-carousel-5.jpg",
    thumbnail: "/img/carousel/ae-carousel-5.jpg",
    alt: "aevias meetings example one",
  },
];

const LandingPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  // const breakpoints = useBreakpoint();
  const scrollHere = useRef(null);
  const toast = useToast();

  useEffect(() => {
    if (isHeroTextHovered) {
      console.log("hovered");
    }
  }, [isHeroTextHovered]);

  return (
    <Layout location={location}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        minH="100vh"
        maxH="672px"
      >
        <Box
          display={{ base: "block", md: "none" }}
          position="relative"
          zIndex="5"
          px={{ base: "24px", md: "6rem" }}
        >
          <Box
            display="flex"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
          >
            <MotionText
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <MotionText
                animate={{
                  opacity: 1,
                  transition: { duration: 1.2, delay: 0 },
                }}
                initial={{ opacity: 0 }}
                as="span"
                mr="4"
                style={{
                  color: "#FFFFFF",
                  width: "100%",
                  position: "relative",
                  fontFamily: "Antonio",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Our Business is Engaging People
              </MotionText>
              <MotionText
                ref={heroTextRef}
                onClick={() => {
                  scrollHere.current.scrollIntoView({ behavior: "smooth" });
                  window.scrollTo({
                    top: 600,
                    behavior: "smooth",
                  });
                }}
                animate={{
                  opacity: 1,
                  transition: { duration: 1.2, delay: 0.6 },
                }}
                initial={{ opacity: 0 }}
                whileHover={{
                  y: -12,
                  transition: {
                    ease: "easeInOut",
                    duration: 0.6,
                    repeat: Infinity,
                    repeatType: "mirror",
                  },
                }}
                as="span"
                style={{
                  color: "#C3F9E0",
                  width: "100%",
                  position: "relative",
                  fontFamily: "Antonio",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                through Event Experiences
              </MotionText>
            </MotionText>
          </Box>
        </Box>

        <Box
          display={{ base: "none", md: "block" }}
          position="relative"
          zIndex="5"
          px={{ base: "24px", md: "6rem" }}
        >
          <Box
            display="flex"
            alignItems="center"
            color="#FFFFFF"
            fontSize={{ base: "48px", md: "64px", lg: "80px" }}
          >
            <motion.div
              animate={{ opacity: 1, transition: { duration: 1.2 } }}
              initial={{ opacity: 0 }}
            >
              <Text
                as="h1"
                color="#FFFFFF"
                fontSize={{ base: "48px", md: "64px", lg: "80px" }}
                fontFamily="'Antonio'"
                mr={{ base: 4, md: 4, lg: 6 }}
              >
                Our Business
              </Text>
            </motion.div>
            <motion.div
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0.4 },
              }}
              initial={{ opacity: 0 }}
            >
              <Text
                as="h1"
                color="#FFFFFF"
                fontSize={{ base: "48px", md: "64px", lg: "80px" }}
                fontFamily="'Antonio'"
              >
                is Engaging People
              </Text>
            </motion.div>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            color="#FFFFFF"
            fontSize={{ base: "48px", md: "64px", lg: "80px" }}
          >
            <motion.div
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0.8 },
              }}
              initial={{ opacity: 0 }}
            >
              <Text
                pl="8rem"
                mr="4"
                as="h1"
                color="#FFFFFF"
                fontSize={{ base: "48px", md: "64px", lg: "80px" }}
              >
                through
              </Text>
            </motion.div>

            <motion.span
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 1.2 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Event Experiences
            </motion.span>
          </Box>
        </Box>

        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroSlider />
        </Box>
      </Box>

      <Box ref={scrollHere} as="section">
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "40px", md: "80px", lg: "8rem" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-md">
                {inView && (
                  <Grid
                    gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "1rem" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box>
                      <Text as="h2" className="ae-title-two" mb="6">
                        We’re Aevias (
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.4 },
                          }}
                          display="inline-block"
                          className="ae-title-two"
                        >
                          ah
                        </MotionText>
                        -
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 0.8 },
                          }}
                          display="inline-block"
                          className="ae-title-two"
                        >
                          vee
                        </MotionText>
                        -
                        <MotionText
                          initial={{ opacity: 0, y: -40 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.4, delay: 1.2 },
                          }}
                          display="inline-block"
                          className="ae-title-two"
                        >
                          ess
                        </MotionText>
                        ), a full-service corporate event management agency that
                        creates, collaborates, innovates, and disrupts.
                      </Text>
                      <Text as="p" className="ae-desc-one">
                        Our business is creating inspiring solutions designed to
                        nurture connectedness and engagement through carefully
                        designed event experiences among your customers, your
                        employees and partners.
                      </Text>
                      <br />
                      <Link
                        alignSelf="flex-end"
                        fontSize="24px"
                        lineHeight="32px"
                        fontWeight="600"
                        fontFamily="'Montserrat'"
                        textDecor="underline"
                        color="secondary.500"
                        onClick={() => navigate("/why-aevias")}
                      >
                        Learn More
                      </Link>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <img
                        src="/img/ae-our-story.png"
                        alt="the story of aevias"
                      />
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>
      </Box>

      <Box bg="#F4F4FF" as="section">
        <Box py="8rem" className="wrapper-xl" fontWeight="semibold">
          <Text
            as="h2"
            color="secondary.500"
            fontSize={{ base: "56px", md: "64px", lg: "80px" }}
            lineHeight={{ base: "64px", md: "72px", lg: "88px" }}
            fontWeight="300"
            letterSpacing="-5px"
            mb="4"
          >
            Our Solutions
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            lineHeight={{ base: "24px", md: "26px" }}
            mb={{ base: 12, md: 16 }}
          >
            Achieving your business goals through connection, experiences, and
            recognition.
          </Text>

          <Grid
            justifyContent="center"
            textAlign="center"
            alignItems="center"
            gridTemplateColumns={{ base: "none", md: "1fr 1fr 1fr 1fr" }}
            gridTemplateRows={{ base: "auto auto auto auto", md: "none" }}
            gridColumnGap={{ base: 0, md: "4rem" }}
            gridRowGap={{ base: "40px", md: 0 }}
          >
            <Grid
              gridTemplateRows={{
                base: "auto auto auto",
                md: "164px 80px 220px",
              }}
              rowGap="1rem"
              justifyContent="center"
            >
              <Box width="164px" height="164px" mx="auto">
                <img
                  src="/img/aevias-meetings.svg"
                  alt="Streamline your process and improve"
                  draggable="false"
                />
              </Box>
              <Text
                as="h4"
                fontSize={{ base: "20px", md: "24px" }}
                lineHeight={{ base: "28px", md: "32px" }}
                color="secondary.500"
                alignSelf="center"
                onClick={() => navigate("/services/meeting-and-conferences")}
                cursor="pointer"
                _hover={{ textDecor: "underline" }}
              >
                Meetings and
                <br />
                Conferences
              </Text>
              <Text
                as="p"
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight={{ base: "22px", md: "24px" }}
              >
                From small, strategic retreats to large conferences or trade
                shows, we partner with you every step of the way to strategize,
                design and implement business events that meet your goals to
                deliver results.
              </Text>
            </Grid>

            <Grid
              gridTemplateRows={{
                base: "auto auto auto",
                md: "164px 80px 220px",
              }}
              rowGap="1rem"
              justifyContent="center"
            >
              <Box width="164px" height="164px" mx="auto">
                <img
                  src="/img/aevias-tickets.svg"
                  alt="Streamline your process and improve"
                  draggable="false"
                />
              </Box>
              <Text
                alignSelf="center"
                as="h4"
                fontSize={{ base: "20px", md: "24px" }}
                lineHeight={{ base: "28px", md: "32px" }}
                color="secondary.500"
                onClick={() => navigate("/services/incentive-travel-programs")}
                cursor="pointer"
                _hover={{ textDecor: "underline" }}
              >
                Incentive Travel
                <br />
                Programs
              </Text>
              <Text
                as="p"
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight={{ base: "22px", md: "24px" }}
              >
                Travel has proven to be a great way to recognize exceptional
                performance. We’ll work with you to create travel experiences
                that will surprise and delight your employees and teams with a
                focus on inspiring connections, communication and results.
              </Text>
            </Grid>

            <Grid
              gridTemplateRows={{
                base: "auto auto auto",
                md: "164px 80px 220px",
              }}
              rowGap="1rem"
              justifyContent="center"
            >
              <Box width="164px" height="164px" mx="auto">
                <img
                  src="/img/aevias-events.svg"
                  alt="Streamline your process and improve"
                  draggable="false"
                />
              </Box>
              <Text
                as="h4"
                fontSize={{ base: "20px", md: "24px" }}
                lineHeight={{ base: "28px", md: "32px" }}
                color="secondary.500"
                alignSelf="center"
                onClick={() => navigate("/virtual-event-experiences")}
                cursor="pointer"
                _hover={{ textDecor: "underline" }}
              >
                Virtual
                <br />
                Events
              </Text>
              <Text
                as="p"
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight={{ base: "22px", md: "24px" }}
              >
                Designing engaging experiences that are "screen-first" are key
                to delivering a successful virtual event. We'll work with you to
                determine what tools and formats will best meet your business
                goals when bringing your attendees together online.
              </Text>
            </Grid>

            <Grid
              gridTemplateRows={{
                base: "auto auto auto",
                md: "164px 80px 220px",
              }}
              rowGap="1rem"
              justifyContent="center"
            >
              <Box width="164px" height="164px" mx="auto">
                <img
                  src="/img/aevias-marketplace.svg"
                  alt="Streamline your process and improve"
                  draggable="false"
                />
              </Box>
              <Text
                as="h4"
                fontSize={{ base: "20px", md: "24px" }}
                lineHeight={{ base: "28px", md: "32px" }}
                color="secondary.500"
                alignSelf="center"
                onClick={() =>
                  toast({
                    title: "Exhibit management coming soon!",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                  })
                }
                cursor="pointer"
                _hover={{ textDecor: "underline" }}
              >
                Exhibit
                <br />
                Management
              </Text>
              <Text
                as="p"
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight={{ base: "22px", md: "24px" }}
              >
                From concept to execution, we specialize in crafting immersive
                exhibit experiences that captivate audiences, amplify your
                message, and generate valuable leads for business growth. Trust
                us to help you leave a lasting impression on the exhibition
                floor.
              </Text>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        display={{ base: "flex", md: "none" }}
        flexDir="column"
        overflow="hidden"
      >
        <Box
          display={{ base: "flex", md: "grid" }}
          gridTemplateColumns="auto auto auto"
          overflowX="scroll"
        >
          <img
            src="/img/aevias-landing-story-one.jpg"
            alt="Aevias landing story one"
            draggable="false"
          />
          <img
            src="/img/aevias-landing-story-two.jpg"
            alt="Aevias landing story two"
          />
          <img
            src="/img/aevias-landing-story-four.jpg"
            alt="Aevias landing story three"
          />
        </Box>
      </Box>
      <Box as="section" display={{ base: "none", md: "block" }}>
        <Grid
          gridTemplateColumns={{ base: "none", md: "1fr 1fr 1fr" }}
          gridTemplateRows={{ base: "repeat(3,auto)", md: "none" }}
        >
          <Box
            height="420px"
            backgroundImage="url('/img/aevias-landing-story-one.jpg')"
            backgroundSize="cover"
            position="relative"
          />
          <Box
            height="420px"
            backgroundImage="url('/img/aevias-landing-story-two.jpg')"
            backgroundSize="cover"
            position="relative"
          />
          <Box
            height="420px"
            backgroundImage="url('/img/aevias-landing-story-four.jpg')"
            backgroundSize="cover"
            position="relative"
            backgroundPosition="center"
          />
        </Grid>
      </Box>
      <Box
        mt="16rem"
        backgroundColor="primary.500"
        as="section"
        position="relative"
        py={{ base: "140px", md: "160px", lg: "240px" }}
        pb={{ base: "2rem", md: "4rem", lg: "8rem" }}
      >
        <Box className="wrapper-xl">
          <Box
            zIndex="9"
            top={{ base: "-12rem", sm: "-6rem", md: "-9.5rem", lg: "-11rem" }}
            position="absolute"
            className="wrapper-xl"
          >
            <Text
              as="span"
              fontSize={{ base: "24px", md: "28px", lg: "32px" }}
              lineHeight={{ base: "32px", md: "36px", lg: "40px" }}
              color="secondary.500"
            >
              Our Story
            </Text>
            <Text
              as="h2"
              color="secondary.500"
              letterSpacing={{ base: "-3px", md: "-5px" }}
              fontSize={{ base: "40px", md: "64px", lg: "80px" }}
              lineHeight={{ base: "48px", md: "72px", lg: "88px" }}
              mb="8"
            >
              We’re dedicated to a single purpose - engaging people through our
              carefully designed
              <br />
              event experiences.
            </Text>
            <Text
              as="span"
              fontSize={{ base: "18px", md: "20px", lg: "24px" }}
              lineHeight={{ base: "26px", md: "28px", lg: "32px" }}
              color="#000000"
              fontWeight="semibold"
              cursor="pointer"
              _hover={{ textDecor: "underline" }}
              onClick={() => navigate("/why-aevias")}
            >
              Learn more about the team {">"}
            </Text>
          </Box>
        </Box>

        <Box display={{ base: "none", md: "flex" }}>
          <Carousel>
            {IMAGES.map((image, index) => {
              return (
                <Box key={index} width="480px" height="240px" mr="24px">
                  <img
                    style={{ height: "100%", width: "100%" }}
                    src={image.src}
                    alt={image.alt}
                    draggable="false"
                  />
                </Box>
              );
            })}
          </Carousel>
        </Box>

        <Box
          display={{ base: "flex", md: "none" }}
          flexDir="column"
          overflow="hidden"
          marginBottom="6"
        >
          <Box
            display={{ base: "flex", md: "grid" }}
            gridTemplateColumns="auto auto auto"
            overflowX="scroll"
          >
            <img
              style={{ margin: "0 24px" }}
              src={IMAGES[0].src}
              alt={IMAGES[0].alt}
            />
            <img
              style={{ margin: "0 24px" }}
              src={IMAGES[1].src}
              alt={IMAGES[1].alt}
            />
            <img
              style={{ margin: "0 24px" }}
              src={IMAGES[2].src}
              alt={IMAGES[2].alt}
            />
            <img
              style={{ margin: "0 24px" }}
              src={IMAGES[3].src}
              alt={IMAGES[3].alt}
            />
            <img
              style={{ margin: "0 24px" }}
              src={IMAGES[4].src}
              alt={IMAGES[4].alt}
            />
          </Box>
        </Box>
      </Box>
      <Box py="6rem" as="section">
        <Box as="section" className="wrapper-md">
          <InView triggerOnce threshold={0.5}>
            {({ inView, ref }) => (
              <Box position="relative" ref={ref}>
                {inView && (
                  <Box position="relative" zIndex="2">
                    <Box
                      display="flex"
                      flexDir="column"
                      alignItems={{ base: "center", md: "initial" }}
                    >
                      <Text as="h2" className="ae-title-one" mb="16">
                        Some of Our Clients
                      </Text>
                      <Grid
                        gridTemplateColumns={{
                          base: "none",
                          md: "repeat(5, 1fr)",
                        }}
                        gridTemplateRows={{
                          base: "repeat(5, auto)",
                          md: "none",
                        }}
                        gridColumnGap={{ base: 0, md: "2rem" }}
                        gridRowGap={{ base: "1rem", md: 0 }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img
                          style={{ alignSelf: "center", margin: "auto" }}
                          src="/img/ae-vw.png"
                          alt="Aevias Volkswagen Partner"
                        />
                        <img
                          style={{ alignSelf: "center", margin: "auto" }}
                          src="/img/ae-audi.png"
                          alt="Aevias Audi Partner"
                        />
                        <img
                          style={{ alignSelf: "center", margin: "auto" }}
                          src="/img/logos/ae-hsbc.png"
                          alt="Aevias HSBC"
                        />
                        <img
                          style={{ alignSelf: "center", margin: "auto" }}
                          src="/img/ae-nissan.png"
                          alt="Aevias Nissan Partner"
                        />
                        <img
                          style={{ alignSelf: "center", margin: "auto" }}
                          src="/img/logos/ae-hootsuite.png"
                          alt="Aevias Hoot Suite Partner"
                        />
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </InView>
        </Box>
        <Box className="wrapper-xl">
          <InView triggerOnce threshold={0.5}>
            {({ inView, ref }) => (
              <Box position="relative" ref={ref}>
                {inView && (
                  <Grid
                    gridTemplateColumns={{ base: "none", md: "repeat(5, 1fr)" }}
                    gridTemplateRows={{ base: "repeat(5, auto)", md: "none" }}
                    gridColumnGap={{ base: 0, md: "2rem" }}
                    gridRowGap={{ base: "1rem", md: 0 }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      style={{ margin: "auto", marginTop: "40px" }}
                      src="/img/ae-teck.png"
                      alt="Aevias Teck partner"
                    />
                    <img
                      style={{ margin: "auto", marginTop: "40px" }}
                      src="/img/ae-bc.png"
                      alt="Aevias British Columbia Partner"
                    />
                    <img
                      style={{ margin: "auto" }}
                      src="/img/ae-avisowealth.png"
                      alt="Aevias Aviso Wealth Partner"
                    />
                    <img
                      style={{ margin: "auto" }}
                      src="/img/logos/ae-tfp.jpg"
                      alt="Aevias The Fertility Partners"
                    />
                    <img
                      style={{ margin: "auto" }}
                      src="/img/logos/ae-trcr.jpg"
                      alt="Aevias Technical and Research Committee on Reclamation Partner"
                    />
                  </Grid>
                )}
              </Box>
            )}
          </InView>
        </Box>
      </Box>
      <Box
        mt={{ base: "160px", md: "180px", lg: "240px" }}
        backgroundColor="primary.500"
        as="section"
        position="relative"
        py={{ base: "60px", md: "80px", lg: "120px" }}
        pb={{ base: "1rem", md: "4rem", lg: "4rem" }}
      >
        <Box className="wrapper-xl">
          <Text
            top={{ base: "-5.5rem", md: "-12rem", lg: "-9rem" }}
            position="absolute"
            whiteSpace="pre-wrap"
            as="h2"
            color="secondary.500"
            letterSpacing="-5px"
            fontSize={{ base: "48px", md: "64px", lg: "80px" }}
            lineHeight={{ base: "56px", md: "72px", lg: "88px" }}
          >
            Check out our
            <br />
            latest virtual event
          </Text>

          <Box>
            <YouTube
              containerClassName="youtubeContainer"
              videoId="7rsqQi1vIiQ"
              opts={{
                height: "100%",
                width: "100%",
                playerVars: {
                  autoplay: 0,
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* <Box backgroundColor="#F2F2F2" as="section" py="8rem">
        <TestimonialSlider />
      </Box> */}
      <ContactComponent />
    </Layout>
  );
};

export default LandingPage;
