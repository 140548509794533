import { Box } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  pauseOnHover: false,
};

const HeroSlider = () => {
  return (
    <Box
      top="0"
      left="0"
      right="0"
      bottom="0"
      position="absolute"
      overflow="hidden"
    >
      <Slider {...settings}>
        <Box
          height="100%"
          backgroundImage="url('/img/hero-slider-small/hero-1.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
        <Box
          height="100%"
          backgroundImage="url('/img/hero-slider-small/hero-2.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
        <Box
          height="100%"
          backgroundImage="url('/img/hero-slider-small/hero-3.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
        <Box
          height="100%"
          backgroundImage="url('/img/hero-slider-small/hero-4.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />

        <Box
          height="100%"
          backgroundImage="url('/img/hero-slider-small/hero-5.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
      </Slider>
    </Box>
  );
};

export default HeroSlider;
