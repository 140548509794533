/* eslint-disable */
import React, { useReducer, useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import range from "lodash/range";

const WrapperDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

/**
 * This is a replacement for the simple `%` operator as it has issues with negative numbers.
 *
 * @param {Number} dividend - The dividend.
 * @param {Number} divisor - The divisor.
 * @returns {Number} - The remainder.
 */
const mod = (dividend, divisor) =>
  dividend >= 0
    ? dividend % divisor
    : (divisor + -(-dividend % divisor)) % divisor;

function cursorReducer(state, action) {
  switch (action.type) {
    case "set":
      return {
        ...state,
        value: action.value,
        initial: action.initial || state.initial,
        min: Math.floor(action.min),
        max: Math.ceil(action.max),
      };
    default:
      return state;
  }
}

function Carousel(props) {
  const {
    children,
    visibleItems = 3.5,
    preloadRadix = 2,
    ...remainingProps
  } = props;

  const wrapperRef = useRef();

  const [slideCursor, slideCursorDispatch] = useReducer(
    cursorReducer,
    null,
    () => ({
      value: 0,
      initial: 0,
      min: -preloadRadix,
      max: +preloadRadix,
    })
  );

  const speed = 36;

  const [autoScroll, setAutoScroll] = useState(true);
  const [autoScrollDirection, setAutoScrollDirection] = useState(1);

  useEffect(() => {
    if (!autoScroll) {
      return;
    }

    const element = wrapperRef.current;

    let cancel = false;
    let scroll = element.scrollLeft;
    let lastFrameTimestamp = Date.now();

    function animateScroll() {
      const currentTimestamp = Date.now();
      const secondsPassed = (currentTimestamp - lastFrameTimestamp) / 1000;

      scroll += speed * secondsPassed * autoScrollDirection;
      element.scrollLeft = scroll;

      if (!cancel) {
        lastFrameTimestamp = currentTimestamp;
        requestAnimationFrame(animateScroll);
      }
    }

    // function onMouseEnter() {
    //   cancel = true;
    // }

    // function onMouseLeave() {
    //   cancel = false;
    //   scroll = element.scrollLeft;
    //   lastFrameTimestamp = Date.now();
    //   requestAnimationFrame(animateScroll);
    // }

    // element.addEventListener("mouseenter", onMouseEnter);
    // element.addEventListener("mouseleave", onMouseLeave);
    requestAnimationFrame(animateScroll);

    // return () => {
    //   cancel = true;
    //   element.removeEventListener("mouseenter", onMouseEnter);
    //   element.removeEventListener("mouseleave", onMouseLeave);
    // };
  });

  const slides = range(slideCursor.min, slideCursor.max + visibleItems).map(
    (index) => {
      const realIndex = mod(index, children.length);
      const child = children[realIndex];

      return React.cloneElement(child, {
        key: index,
        style: {
          width: `${140 / visibleItems}%`,
          height: "100%",
          flexShrink: 0,
        },
      });
    }
  );

  // function handleScroll(event) {
  //   const element = event.target;

  //   const { scrollLeft, scrollWidth, offsetWidth } = element;

  //   const widthPerItem = offsetWidth / visibleItems;
  //   const distanceFromStart = scrollLeft;
  //   const distanceFromEnd = scrollWidth - offsetWidth - scrollLeft;

  //   const blocksFromStart = distanceFromStart / widthPerItem;
  //   const blocksFromEnd = distanceFromEnd / widthPerItem;

  //   if (blocksFromEnd < preloadRadix || blocksFromStart < preloadRadix) {
  //     const leftDifference = Math.ceil(
  //       Math.max(0, preloadRadix - blocksFromStart)
  //     );
  //     const rightDifference = Math.ceil(
  //       Math.max(0, preloadRadix - blocksFromEnd)
  //     );

  //     slideCursorDispatch({
  //       type: "set",
  //       min: slideCursor.min - leftDifference,
  //       max: slideCursor.max + rightDifference,
  //     });

  //     if (leftDifference > 0) {
  //       requestAnimationFrame(() => {
  //         wrapperRef.current.scrollLeft =
  //           wrapperRef.current.scrollLeft + leftDifference * widthPerItem;
  //       });
  //     }
  //   }
  // }

  return (
    <WrapperDiv
      className="no-scrollbar"
      ref={wrapperRef}
      // onScroll={handleScroll}
      {...remainingProps}
    >
      {slides}
    </WrapperDiv>
  );
}

export default Carousel;
